.footer-container {
    text-align: center;
    margin-bottom: 5rem;
}

.footer-title {
    font-size: 3.5rem;
}

.footer-text {
    font-size: 1.3rem;
    padding: 0 3rem;
}

.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.contact-text {
    font-size: 1.6rem;
}

.contact-meta:not(:last-child) {
    margin-right: 7rem;
}

.footer-divider {
    margin: 5rem auto;
    border-top: .5rem solid #b3b1b2;
    width: 50%;
}

.policies {
    font-size: 1.3rem;
}

.policies:not(:last-child) {
    margin-right: 3rem;
}

.policies:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {

    .footer-divider {
        width: 90%;
    }

}