.single-lesson {
  display: flex;
  justify-content: center;
  height: 80vh;
  padding: 0 10rem;
  margin-bottom: 10rem;
  font-family: 'Poppins', sans-serif;
}

.lesson-container {
  width: 75%;
}

.lesson-title {
  font-size: 3.6rem;
  margin-bottom: 0rem;
}

.lesson-instructor {
  font-size: 1.3rem;
  margin: 0rem;
}

.lesson-description {
  font-size: 1.3rem;
}

.video-wrapper {
  margin-top: 2rem;
}


.instructor-container {
  display: flex;
  align-items: center;
  width: 90%;
}

.unavailable-instructor-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding-left: 1rem;
}

.instructor-meta {
  display: flex;
  align-items: center;
}

.verification-badge {
  height: 1.5rem;
  width: 1.5rem;
}

.upnext-unavailable-post-buy {
  text-decoration: none;
  color: black;
  font-size: 1.6rem;
  border: .1rem solid black;
  border-radius: .8rem;
  padding: 1rem 2rem;
}

.upnext-videos {
  overflow-y: scroll;
  width: 25%;
  margin-top: 4rem;
  height: 80%;
  padding: 0 2rem;
}

.upnext {
  margin-bottom: 2rem;
  font-size: 2.8rem;
}

.upnext-meta {
  display: flex;
  flex-direction: column;
}

.upnext-title {
  font-size: 1.6rem;
}

.upnext-title, .upnext-instructor {
  margin: 0rem;
}

.upnext-thumbnail-container {
    margin-right: 8px;
    height: 94px;
    width: 168px;
    overflow: hidden;
}

.upnext-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: .3rem;
}

.upnext-instructor {
  font-size: 1.3rem;
}

.video-container {
  display: flex;
  margin-bottom: 2rem;
}

.title-link {
  color: #000000;
  text-decoration: none;
  padding-left: 1rem;
}


@media only screen and (max-width: 800px) {
    .single-lesson {
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding: 0rem;
      margin: 2rem auto;
      padding-bottom: 10rem;

    }

    .lesson-title, .instructor-container {
      padding-left: 2rem;
    }

    .lesson-container {
      width: 100%;
    }

    .upnext-videos {
      width: 100%;
      height: auto;
      padding: 0rem;
      margin-top: 4rem;
    }

    .lesson-title {
      font-size: 2.3rem;
    }

    .lesson-instructor {
      font-size: 1.2rem;
      margin-top: 1rem;
    }

    .video-wrapper {
      margin-top: 1rem;
    }

    .upnext-thumbnail-container {
      height: 100%;
      width: 100%;
    }
    
    .video-container {
      flex-direction: column;
    }
}