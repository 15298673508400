.purchase-post-parent-container {
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    width: 50%;
    margin: 5rem auto;
}

.purchase-post-container {
    padding: 0 1.5rem;
    margin-bottom: 3rem;
}

.purchase-post {
    display: flex;
    flex-direction: column;
}

.purchase-post-label {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    
}

.purchase-post-input {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    border-radius: .7rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: transparent;
    font-size: 1.5rem;
    width: 95%;
}

.purchase-post-btn {
    background-color: #000000;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 1.6rem;
    margin-top: 2rem;
    border: .1rem solid #000000;
    padding: 1rem 2rem;
    border-radius: .8rem;
    cursor: pointer;
}

.post-lesson-description {
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 80rem) {
    .purchase-post-parent-container {
        flex-direction: column;
        width: 100%;
    }
}