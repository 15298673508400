.mentor-lesson-container {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .course-header {
    font-size: 2.8rem;
    text-align: center;
  }

  .lesson-link {
    text-decoration: none;
    color: #000000;
  }

  .course-lesson-container {
   width: 90%;
    margin: 0 auto;
  }

  .lesson-section  {
    display: flex;
    margin: 0 auto;
    border-radius: .8rem;
    border: .1rem solid rgb(233, 233, 233);
    height: 7rem;
    padding: 1rem 1rem;
    cursor: pointer;
  }

  .upcoming-lesson-section {
    margin: 0 auto;
    border-radius: .8rem;
    border: .1rem solid rgb(233, 233, 233);
    height: 7rem;
    padding: 1rem 1rem;
    cursor: not-allowed
  }


  .section-title {
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.8rem;
  }

  .lesson-metadata {
    display: flex;
    align-items: center;
  }

  .play-icon {
    margin-right: 3rem;
  }

  .lesson-title {
    font-size: 1.6rem;
  }

  .upcoming-lesson {
    font-size: 1.6rem;
  }

  .next-course-videos {
    overflow-y: scroll;
    width: 25%;
    margin-top: 4rem;
    height: 80%;
    padding: 0 2rem;
  }

  .next-lesson-container {
    width: 90%;
    margin: 0 auto;
  }

  .upgrade-lesson-section {
    align-items: center;
    border-radius: 0.8rem;
    border: 0.1rem solid rgb(233, 233, 233);
    padding: 1rem 1rem;
    cursor: not-allowed;
  }

  .upgrade-lesson-metadata {
    display: flex;
    
  }

  .lesson-upgrade {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
    border-radius: 6px;
    border: 0.4rem solid #ffffff;
    background-color: #FACDC1;
    padding: 1rem 2rem;
    text-decoration: none;
  }

  .upgrade-title {
    font-size: 12px;
  }

  .upgrade-lesson-title {
    font-size: 1.6rem;
  }


  @media only screen and (max-width: 800px) {
    .course-lesson-container {
      width: 95%;
      margin: 0 auto;
    }

    .next-course-videos {
      width: 100%;
      padding: 0 0;
    }
    
    .next-lesson-container {
      width: 90%;
    }

    .section-title {
      font-size: 1.6rem;
    }
  }