.skeleton-text {
    width: 80%;
}

.skeleton-video-wrapper {
    width: 96%;
}

.upnext-videos-skeleton {
    width: 25%;
    height: 80%;
    padding: 0 2rem;
}


@media only screen and (max-width: 80rem) {
    .skeleton-text {
        width: 95%;
    }

    .skeleton-video-wrapper {
        width: 100%;
    }

    .instructor-container {
        width: 45%;
    }

    .upnext-videos-skeleton {
        width: 100%;
        height: auto;
        padding: 0rem;
        margin-top: 4rem;
    }

}