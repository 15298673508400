.mentor-container {
    background-color: #FFF8F1;
    font-family: 'Poppins', sans-serif;
   
}
  .column-wrapper {
    width: 65%;
    margin: 0 auto;
    padding: 2rem 0;
  }


.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .mentor-column {
    margin: auto 0;
  }

  .image-container { 
      text-align: center;
  }

  .mentor-image {
      width: 50%;
      height: auto;
  }

  .mentor-about {
    font-size: 1.6rem;
  }

  .mentor-live-container {
      text-align: center;
      margin-top: 1.8rem;
  }

  .mentor-live {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000000;
    border-radius: 6px;
    border: .4rem solid #ffffff;
    background-color: #FACDC1;
    padding: 1rem 2rem;
    text-decoration: none;
  }

  .extension-workbook-container {
    text-align: center;
    margin-top: 1rem;
  }

  .workbook {
      display: inline-block;
      text-decoration: none;
      color: #000000;
      font-size: 1.2rem;
    }

    .workbook:hover {
        text-decoration: underline;
    }

    .scroll-hint {
      font-size: 1.1rem;
      text-align: center;
      margin-top: .5rem;
    }


  @media only screen and (max-width: 60rem) {
    .row {
        flex-direction: column;
    }
    
    .column-wrapper  {
        width: 95%;
    }

    .mentor-image {
        width: 70%;
        height: auto;
    }
    .mentor-name {
        text-align: center;
    }

    .mentor-about {
      font-size: 1.4rem;
    }
  }
