/* iframe {
    width: 100% !important;
    margin: 0 auto !important;
} */

.workspace-container {
    margin-top: 3rem;
}

.profile-meta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}


.profile-display {
    font-size: 4rem;
}

.member-since-display {
    font-size: 1.3rem;
}

.profile-link-container {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.workspace-btn {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    color: #ffffff;
    background-color: #000000;
    padding: 0.3rem 2rem;
    text-decoration: none;
}
.workspace-btn:not(:last-child) {
    margin-right: 1rem;
}

.parent-post-container {
    margin: 0 auto;
}
.all-posts {
  display: grid;
  grid-auto-rows: max-content; 
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); 
  align-items: center;
  width: 45%;
  margin: 0 auto
}

.post-container {
    width: 95%;
    margin: 3rem auto;
    border-radius: 1rem;
    box-shadow: -2px 4px 28px rgba(0, 0, 0, 0.2); 
	-webkit-box-shadow: -2px 4px 28px rgba(0, 0, 0, 0.2); 
	-moz-box-shadow: -2px 4px 28px rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.thumbnail-container {
    height: 30rem;
    width: 100%;
    overflow: hidden;
}

.unavailable-thumbnail-container {
    height: 25rem;
    width: 100%;
    overflow: hidden;
}


.post-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

}

.link-wrapper {
    text-decoration: none;
}

.post-title {
    color: black;
    font-size: 1.6rem;
    text-decoration: none;
    padding-bottom: 1rem;
}

.post-meta {
    padding: .5rem 2rem;
    font-size: 1.3rem;
}

.author-container {
    display: flex;
    align-items: center;
}

.post-teacher {
    color: #000000;
    font-size: 1.4rem;
}

.verification-badge {
    height: 2rem;
    margin-left: .5rem;
}

.author-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.post-teacher {
    font-weight: 600;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
}

.pagination-keys {
    list-style-type: none;
    margin-right: 1rem;
}

.pagination-btn {
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    padding: 1rem 2rem;
    border: .1rem solid #ffffff;
    border-radius: .8rem;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
    cursor: pointer;
    transition: .2s ease-in-out;
    -webkit-appearance: none;
   -moz-appearance: none;
    appearance: none;
}

.pagination-btn:hover {
    background-color: #000000;
    color: white;
    border: .1rem solid #000000;
}

.active {
    background-color: #000000;
    color: white;
    border: .1rem solid #000000;
}

.post-views {
    font-size: 1.4rem;
    color: #000000;
}

.selected-categories {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.category-selector {
    font-size: 1.6rem;
    padding-right: 1rem;
}

.unavailable-post-container {
    width: 95%;
    margin: 3rem auto;
    border-radius: 1rem;
    box-shadow: -2px 4px 28px rgb(0 0 0 / 20%);
    -webkit-box-shadow: -2px 4px 28px rgb(0 0 0 / 20%);
    -moz-box-shadow: -2px 4px 28px rgba(0, 0, 0, 0.2);
    position: relative;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.formula-logo {
    width: 30%;
    height: auto;
}


.unavailable-post-meta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95%;
    background-color: rgba(0, 0, 0, 0.466);
    padding: 1rem 1rem;
}

.unavailable-post-title {
    color: #ffffff;
    font-size: 16px;
    text-align: right;
}

.unavailable-author-meta {
   
    color: #ffffff;
}

.unavailable-author {
    font-size: 16px;
}

.unavailable-meta-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.unavailable-post-purchase {
    text-decoration: none;
}

.unavailable-author, .unavailable-price {
    text-decoration: none;
}

.unavailable-post-buy {
    display: inline-block;
    color: #ffffff;
    border: 1px solid #ffffff;
    padding: 1rem 5rem;
    border-radius: 2rem;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-top: 1rem;
}

.unavailable-price {
    font-size: 16px;
    text-align: right;
}

.unavailable-author-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.preview-link-wrapper {
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .all-posts {
        width: 95%;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 
    }

    .thumbnail-container { 
        height: 20rem;
    }

  }