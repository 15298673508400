.profile-form-container {
    margin: 6rem auto;
    width: 25%;
    border-radius: 1rem;
    padding: 1rem 2rem;
    background-color: #F6F8FA;
}

.profile-header {
    font-family: 'Poppins', sans-serif;
}
.profile-label {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
}

.profile-label:not(first-child) {
    margin-top: .5rem;
}

.profile-input {
    height: 4rem;
    outline: transparent;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 85%;
    margin: .5rem auto;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}

.bold {
    font-weight: 600;
}



@media only screen and (max-width: 800px) {
    .profile-form-container {
        width: 95%;
    }

    .upnext-thumbnail-container {
        height: 200px;
        width: 100%;
    }

  }