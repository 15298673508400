.lander-hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ededed;
    padding: 4rem 0;

}

.haircation-preview {
    width: 100%;
    height: auto;
}

.lander-value {
    font-size: 3.5rem;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
}

.lander-benefits {
    background-color: #000000;
    width: 80%;
    padding: 2rem 2rem;
    margin: 0 auto;
    border-radius: .8rem;
    transform: translateY(-3rem);
}

.benefits {
    color: #ffffff;
    font-size: 1.6rem;
    list-style: none;
}

.haircation-price {
    margin-top: 3rem;
    width: 100%;
}

.lander-description {
    font-size: 1.6rem;
    margin: 0 2rem;
}

.haircation-cta {
    display: flex;
    justify-content: center;
}

.lander-btn {
    display: inline-block;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif!important;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    padding: 1rem 3rem;
    border: .1rem solid #fcc4b3;
    border-radius: 27px;
    text-transform: uppercase;
    background-color: #fcc4b3;
    text-decoration: none;
    margin-top: 2.5rem;
    cursor: pointer;
}

.membership-price {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
}

.lander-preview {
    font-size: 2.8rem;
    font-weight: 600;
    text-align: center;
    line-height: 3.5rem;
    margin-top: 5rem;
    text-transform: uppercase;
}

.preview-description {
    font-size: 1.6rem;
    margin: 1rem 2rem;
    text-align: center;
}

.featured-video-container {
    display: flex;
    justify-content: center;
}

.lander-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.featured-style, .featured-cta {
    width: 90%;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.lander-educators {
    font-size: 3.5rem;
    text-align: center;
    margin: 0 2rem;
}

.educator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.educator-image {
    width: 90%;
}

.sub-educator-image {
    width: 60%;
    margin-top: 3rem;
}



.educator-caption {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
}


.underline {
    text-decoration: underline;
}

.bold {
    font-weight: 600;
}

.image-rounded {
    border-radius: 20rem;
}

.m-4 {
    margin-top: 4rem;
}

@media only screen  and (min-width: 1000px) {
    .lander-benefits {
        width: 50%;
        text-align: center;
    }
    
    .haircation-preview {
        width: 36%;
        height: auto;
    }

    .haircation-price {
        width: 50%;
    }

    .lander-details {
        width: 50%;
        margin: 0 auto;
    }

    .lander-action {
        width: 25%;
        margin: 0 auto;
    }
}