.login-container {
    margin: 15rem auto;
    width: 25%;
    border-radius: 1rem;
    padding: 5rem 0;
}

.login-text {
    padding-left: 3rem;
    text-transform: uppercase;
}
    

.login-form {
    display: flex;
    flex-direction: column;
    
}

.login-input {
    height: 4rem;
    outline: transparent;
    border-radius: .3rem;
    border: 1.5px solid black;
    width: 85%;
    margin: 1rem auto;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}

.form-submit {
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    background-color: #000000;
    border: #000000;
    padding: 1rem 1rem;
    width: 88%;
    margin: 0 auto;
    border-radius: .5rem;
    color: #fff;
    cursor: pointer;
}

.create-account-btn {
    display: block;
    margin: 3rem auto;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    border: 1.5px solid black;
    padding: 1rem 1rem;
    width: 85%;
    border-radius: .5rem;
    color: #000000;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.workspace-login {
    margin: 2rem auto;
}


@media only screen and (max-width: 800px) {
    .login-container {
        width: 95%;
        padding: 1rem 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

  }