.hero-container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg,rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.3) 100%),url(https://haircation.com/wp-content/uploads/2021/01/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
}

.hero-value {
    font-size: 7rem;
    
}

.hero-support {
    font-size: 2rem;
    margin-top: 2rem;
}

.refine-section {
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.refine-intro {
    background-color: #ECECEC;
    border-radius: .8rem;
    width: 25%;
    padding: 2rem 4rem;
}

.featured-online {
    text-align: center;
    background-color: #fc4373;
    color: white;
    border-radius: .8rem;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.refine-hook {
   font-weight: 500; 
}

.cta-lander {
    display: inline-block;
    background-color: #fc4373;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    text-decoration: none;
    padding: 1rem 2rem;
    border-radius: .8rem;
    margin-top: 1.5rem;
}

.features-section {
    display: flex;
    flex-direction: column;
    margin: 5rem 0;
    padding: 5rem 0rem;
    background-color: #3A3B40;
    color: #ffffff;
}

.features-container {
    margin: 0 auto;
    padding: 0 2rem;
}

.computer-image {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.features-title {
    font-size: 3.5rem;
    margin-bottom: 2rem;
}

.features-list {
    list-style-type: none;
    line-height: 4.2rem;
    font-size: 1.6rem;
}

.features-items {
    border-bottom: .1rem solid #ffffff;
}

.pricing-section {
    text-align: center;
    padding: 0 1.5rem;
}

.pricing-title {
    font-size: 3.8rem;
    line-height: 4.5rem;
    text-transform: uppercase;
}

.pricing-title span {
    color: #fc4373;
}

.regular-price {
    color: #666666;
    font-size: 1.6rem;
    text-decoration: line-through;
}

.discounted-price {
    font-size: 1.9rem;
    color: #000000;
    font-weight: 600;
}

.note-text {
    margin: 1rem 0;
    font-size: 1.3rem;
}


@media only screen and (max-width: 800px) {
    .hero-value {
       font-size: 3.5rem; 
       text-align: center;
       line-height: 4.2rem;
    }

    .hero-support {
        text-align: center;
    }

    .refine-intro {
        width: 90%;
        padding: 2rem 1rem;
    }

    .features-title {
        font-size: 2.8rem;
        line-height: 4.2rem;

    }

  }