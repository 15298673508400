.one-container {
    width: 60%;
    margin: 3rem auto;
}

.one-meta-container {
    display: flex;
    align-items: center;
}

.one-description {
    font-size: 1.4rem;
    padding-right: 4rem;
    margin-bottom: 2rem;
}

.one-title {
    font-size: 3.0rem;
    text-align: center;
}

.one-price {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
}

.one-divider {
    width: 50%;
    margin: 2rem auto;

}
.one-btn {
    display: inline-block;
    color: #ffffff;
    font-size: 1.5rem;
    border-color: #000000;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    background-color: #000000;
    padding: 1rem 2rem;
    text-decoration: none;
    border-radius: 1rem;
}

.model-image {
    height: auto;
    width: 40%;
}

.hide-d {
    display: none;
}

.one-btn-container {
    text-align: center;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 800px) {
    .one-container {
        width: 95%;
    }

    .one-meta-container {
        flex-direction: column;
    }

    .one-description {
        padding-right: 0;
    }

    .hide-d {
        display: inline-block;
    }

    .model-image {
        width: 100%;
        height: auto;
    }
}