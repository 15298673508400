.profile-image-container {
	position: relative;
}

.profile-image-container button {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
}

.profile-image-thumbnail {
	height: 15rem;
	width: 15rem; 
	border-radius: 15rem; 
	overflow: hidden; 
}

.profile-image-thumbnail img { 
	width: 100%;
    height: 100%;
    object-fit: cover;
}