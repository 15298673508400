
.category-navigation-list {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 3.3rem;
    z-index: 99;

}

.closed {
   display: none;
}


.close-image {
    z-index: 999;
    position: absolute;
    top: 5rem;
    right: 10rem;
}

.hidden-image {
    display: none;
}

.category-navigation-items {
    list-style: none;
    transition: .2s all ease-in;
    cursor: pointer;
}

.category-navigation-items:hover {
    text-decoration: underline;
}

.navigation-items a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
}

.navigation-items a:hover {
    text-decoration: underline;
    color: #bdbdbd;
}

@media only screen and (max-width: 800px) {
    .category-navigation-items {
        font-size: 3rem;
    }

    .close-image {
        top: 12rem;
        right: 3rem;
    }
}