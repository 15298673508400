.discount-container {
    margin: 8rem 0;
}

.discount-title {
    font-size: 8.0rem;
    text-align: center;
    line-height: 6.5rem;
}

.discount-subheader {
    font-size: 2.5rem;
    text-align: center;
}

.discount-card-container {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); 
    align-items: center;
    width: 45%;
    margin: 3rem auto
    
}

.discount-card {
    border: 1px solid #e1e5ea;
    border-radius: .8rem;
    padding: 2rem 1rem;
    
}


.discount-link {
    text-decoration: none;
}

.discount-card-image-container {
    text-align: center;
}

.discount-brand-image {
    border-radius: .8rem;
}

.discount-brand-title {
    font-size: 1.6rem;
    color: #000000;
}

.discount-brand-description {
    font-size: 1.4rem;
    color: #696973;

}

.resize-image {
    width: 80%;
    height: auto;
}

@media only screen and (max-width: 800px) {
    .discount-title {
        font-size: 5rem;
    }

    .discount-card-container {
        width: 95%;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); 
    }

    .thumbnail-container { 
        height: 20rem;
    }

    .discount-card {
        margin-top: 2rem;
    }

  }