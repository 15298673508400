.announcement-container {
    width: 30%;
    height: 80%;
    margin: 0 auto;
    background-color: #000000;
    color: #ffffff;
    border-radius: 8px;
    padding: 10px 10px;
    margin-bottom: 28px;
}

.announcement-title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
}

.announcement-description {
    font-size: 13px;
    padding: 0 10px;
}

@media only screen and (max-width: 800px) {
    .announcement-container {
        width: 90%;
    }
}