
.navigation-list {
    position: absolute;
    top: 10rem;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000000ed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 5rem;

}

.closed {
   display: none;
}

.open {
    
}

.navigation-items {
    list-style: none;
    transition: .2s all ease-in;
}

.navigation-items a {
    color: inherit;
    font-family: inherit;
    text-decoration: none;
}

.navigation-items a:hover {
    text-decoration: underline;
    color: #bdbdbd
}

.navigation-item-container {
    position: relative;
    bottom: 6rem;
    text-align: center;
}

.guest-cta {
    font-size: 8rem;
}

.guest-cta-small {
    font-size: 6rem;
    font-weight: 300;
}

.cta-btn {
    color: #FFFFFF ;
    border-color: #fc3131;
    background-color: #fc3131;
    border-radius: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding: 1rem 5rem;
    text-decoration: none;
}

@media only screen and (max-width: 800px) {
    .navigation-list {
        font-size: 3rem;
        top: 5.5rem;;
    }

    .guest-cta {
        margin-top: 7rem;
        font-size: 3.5rem;
        line-height: 3.8rem;
        text-align: center;
    }

    .guest-cta-small {
        font-size: 2rem;
    }

    .cta-btn {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
  }