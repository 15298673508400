.menu-container {
 background-color: white;
}

.menu-container-active {
    background-color: #000000e6;
    z-index: 999;
    position: relative;
}

.nav-uni {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 1rem;
}


.logo {
    height: 10rem;
    width: auto;
    filter: invert(100%);
}

.white {
    /* filter: invert(0%); */
}

.stopScroll { 
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: hidden !important;
}

.scroll {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; 
}

@media only screen and (max-width: 800px) {
    .logo {
       height: 5rem;
    }

    .nav-uni {
        justify-content: space-between;
       
    }
  }
