.formula-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  
  .formula-image {
    height: auto;
    width: 25%;
  }
  
  .formula-title {
    text-align: center;
  }
  
  .formula-instruction-container {
    background-image: linear-gradient(90deg,#fdb4b1 0%,#fea7a6 100%);
    border-radius: 8px;
    color: white;
    padding: 15px 15px;
    box-shadow: 0 2px 18px 0 rgb(254 167 166 / 52%);
    width: 50%;
    height: max-content;
  }
  
  @media only screen and (max-width: 800px) {
    .formula-instruction-container {
        width: 85%;
        margin-top: 2rem;
    }

    .formula-container {
    flex-direction: column;
    font-family: 'Poppins'
   }
    
    .formula-image {
      width: 90%;
    }

    .formula-application, .formula-toning, .formula-root, .formula-mids {
        margin-bottom: 1rem;
    }
  }