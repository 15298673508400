.locator-container {
    width: 60%;
    margin: 0 auto;
    padding-top: 3rem;
}

.locator-title {
    font-size: 3rem;
    text-align: center;
}

.locator-description {
    font-size: 1.6rem;
}

.locator-key {
    width: 80%;
    height: auto;
}

@media only screen and (max-width: 800px) {
    .locator-container {
        width: 95%;
    }

    .locator-key {
        width: 100%;
    }

    .locator-description {
        margin-bottom: 2rem;
        font-size: 1.3rem;
    }
}