.profile-container {
 width: 25%;
 margin: 4rem auto;
}

.profile-name {
    font-size: 2.8rem;
    text-align: center;    
    margin-bottom: 2rem;
}

.profile-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1.8rem;
    padding: 1rem 1.3rem;
}

.profile-items {
    border: .1rem solid #c4c4c4;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    text-decoration: none;
    color: #000000;
}

.profile-items:hover {
   background-color: #ededed;
}

.profile-items:not(:first-child) {
    margin-top: 1.2rem;
}

.profile-logout {
    text-align: center;
    text-decoration: none;
    margin-top: 3rem;
    padding: 1rem 2rem;
    background-color: #000000;
    border-radius: 1rem; 
    color: #ffffff;
}

@media only screen and (max-width: 800px) {
    .profile-container {
        width: 95%;
    }

  }