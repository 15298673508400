.subscription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.6rem;
    margin: 5rem auto;
}

.subscription-header {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.active-subscriptions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.membership-container {
     display: flex;
}

.subscription-title {
    font-weight: 800;
    margin-right: 1rem;
}

.subscription-renew {
    margin-bottom: 1rem;
}

.cancel-subscription {
    cursor: pointer;
}

.transaction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cancel-subscription-btn {
    background-color: transparent;
    border: 1px solid transparent;
    outline: transparent;
    cursor: pointer;
}
.cancel-subscription-btn:hover {
 text-decoration: underline;
}


@media only screen and (max-width: 800px) {
    .subscription-container {
        text-align: center;
        padding: 0 2rem;
    }
}