.marketing-container {
    width: 50%;
    margin: 50px auto;
}

.marketing-video-title {
    font-size: 40px;
    text-align: center;
}

.cta-btn-true {
    display: inline-block;

}

.cta-btn-false {
    display: none;
}

.marketing-btn-container {
    text-align: center;
}

.marketing-cta-btn {
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif!important;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    padding: 1rem 3rem;
    border: 0.1rem solid #fcc4b3;
    border-radius: 27px;
    text-transform: uppercase;
    background-color: #fcc4b3;
    text-decoration: none;
    margin-top: 2.5rem;
    cursor: pointer;
}

.marketing-description {
    font-size: 16px;
    padding: 10px 15px;
}

.faq-image-container {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 20px;
}

.faq-image {
    width: 35%;
}

.faq-container {
    padding: 0 20px;
}

.marketing-cta-text {
    font-size: 16px;
    padding: 10px 15px;

}

@media only screen and (max-width: 600px) {
    .marketing-container {
      width: 100%;
    }

    .marketing-video-title {
        font-size: 32px;
        padding: 15px 5px;

    }

    .faq-image {
        width: 80%;
    }
  }