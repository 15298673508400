.registration-container {
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    width: 50%;
    margin: 5rem auto;
}

.registration-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
   padding-right: 3rem;
}

.list-style { 
    list-style-type: none;
    background-image: url(https://qn03e1pj8r21z48mq2u9ctcv-wpengine.netdna-ssl.com/wp-content/themes/samcart/assets/images/check.svg);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: top 1px left;
    margin: 0;
    padding: 0 1rem 1.8rem 3.3rem;
  }

.registration-form {
    width: 50%;
}

.membership-image {
    width: 50%;
    height: auto;
    border-radius: 20rem;
}

.membership-title {
    font-size: 3rem;
    text-align: center;
}

.membership-description {
    margin-top: 2rem;
    font-size: 1.6rem;

}

.member-form {
    display: flex;
    flex-direction: column;
    width: 95%
}

.member-label {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
}

.member-input {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    border-radius: .7rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: transparent;
    font-size: 1.5rem;
    width: 100%;
}

.member-input:not(:last-child) {
    margin-bottom: 1.5rem;
}


.member-submit {
    background-color: #000000;
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    font-size: 1.6rem;
    margin-top: 2rem;
}

.credit-card-meta {
    display: flex;
    align-items: center;
    width: 95%;
}

.credit-container:not(:last-child) {
    margin-right: 4rem;
}

.order-overview {
    margin-top: 2rem;
}



.overview-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FDF0E8;
    font-size: 1.6rem;
    padding: 1rem 1rem; 
    border-radius: .8rem;
}

.order-trial {
    font-size: 1.6rem;
}

.order-price {
    font-size: 1.4rem;
}

.post-image {
    width: 100%;
    height: auto;
}

.upgrade-link-btn {
    color: black;
    text-align: center;
}

@media only screen and (max-width: 80rem) {
    .registration-container {
        flex-direction: column;
        width: 100%;
    }

    .registration-content {
        width: 90%;
        margin: 0 auto;
        padding: 0 1.5rem;
    }

    .registration-form {
        width: 100%;
        margin: 3rem auto;
    }

    .member-input {
        width: 97%;
    }

    .member-form {
        margin: 0 auto;
    }
}


