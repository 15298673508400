.skeleton-lesson-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.skeleton-header {
    width: 50%;
    text-align: center;
}

.skeleton-title {
    margin: auto 1rem;
    width: 50%;
}