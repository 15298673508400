.assignment-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 4rem auto;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }

  .assignment-description {
      font-size: 1.5rem;
      text-align: left;
  }
  
  .assignment-submit-title {
    text-align: center;
    font-size: 1.6rem;
  }

  .assignment-image-title {
      margin-top: 3rem;
  }

  .instruction-title {
      font-size: 1.7rem;
      font-weight: 800;
  }

  .assignment-title {
      margin-bottom: 2rem;
  }
  
  .list {
    text-align: left;
  }

  .center {
      text-align: center;
  }

  .assignment-submit-title {
      font-size: 1.8rem;
      text-align: center;
      margin-top: 3rem;
      font-weight: 800;
  }

  .assignment-description li {
      list-style-type: none;
  }

  .assignment-btn-container {
      text-align: center;
      margin-bottom: 3rem;
  }

.assignment-submit-btn {
    background-color: #000;
    padding: 1rem 2rem;
    color: white;
    border: .1rem solid #000;
    border-radius: .8rem;
}

.gb-container {
    text-align: center;
    margin-bottom: 80px;
}

.assignment-gb {
    color: black;
    font-size: 16px;

}

  @media only screen and (max-width: 800px) {
      .assignment-container {
          width: 90%;
          
      }
  }